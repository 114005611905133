<template>
  <div class="main-box">
    <el-tabs v-model="type">
      <el-tab-pane label="订单核销" name="1"></el-tab-pane>
      <el-tab-pane label="上门自提记录" name="2"></el-tab-pane>
    </el-tabs>
    <div v-if="type==1">
      <el-form class="form-inline mg-tp-10" :inline="true" size="small">
        <el-form-item label="">
          <el-select v-model="hexiao.type" placeholder="请选择类型">
          <el-option :key="1" label="上门自提核销" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-input v-model="hexiao.code" placeholder="请输入核销码" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" size="small" @click="seachCode(false)">查询</el-button>
        </el-form-item>
      </el-form>
      <div v-if="hexiaoInfo">
        <el-form size="small" label-position="left">
          <el-form-item label="订单号：" :label-width="formLabelWidth">
            {{ hexiaoInfo.order_sn }}
          </el-form-item>
          <el-form-item label="核销码：" :label-width="formLabelWidth">
            {{ hexiaoInfo.hexiao_code }}
          </el-form-item>
          <el-form-item label="商品：" :label-width="formLabelWidth" v-if="hexiaoInfo.goods && hexiaoInfo.goods.length>0">
            <div class="goods-box line">
              <div class="item" v-for="(item,index) in hexiaoInfo.goods" :key="index">
                <img :src="item.goods_img" class="img" />
                <div>
                  <div class="name">{{item.goods_name}}</div>
                  <div class="spec">{{item.specs_name}}</div>
                </div>
                <div class="sum">x{{item.quantity}}</div>
                <div class="price">￥{{item.price}}</div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="自提点：" :label-width="formLabelWidth">
            {{ hexiaoInfo.store_name }}
          </el-form-item>
          <el-form-item label="状态：" :label-width="formLabelWidth">
            {{ hexiaoInfo.hexiao_status_str }}
          </el-form-item>
          <el-form-item label="核销人：" :label-width="formLabelWidth" v-if="hexiaoInfo.hexiao_time">
            {{ hexiaoInfo.user_name }}
          </el-form-item>
          <el-form-item label="核销时间：" :label-width="formLabelWidth" v-if="hexiaoInfo.hexiao_time">
            {{ hexiaoInfo.hexiao_time }}
          </el-form-item>
          <el-popconfirm v-if="!hexiaoInfo.hexiao_time"
              title="确认要核销该订单吗？"
              @confirm="seachCode(true)"
          >
            <el-button type="primary" slot="reference" size="small">核销</el-button>
          </el-popconfirm>
        </el-form>
      </div>
    </div>
    <div v-if="type==2">
      <selffetch-log></selffetch-log>
    </div>
  </div>
</template>
<script>

import selffetchLog from "@/components/Order/SelffetchLog";
export default {
  components: {
    selffetchLog
  },
  data() {
    return {
      source_domain: this.config.SOURCE_DOMAIN,
      type: '1',
      formLabelWidth: '80px',
      hexiao: {
        type: 1,
        code: ''
      },
      hexiaoInfo: false
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {},
  methods: {
    seachCode(status) {
      if(this.hexiao.code == '') {
        this.fail('核销码不能为空')
        return false
      }
      var params = {
        type: 1,
        is_hexiao: status?1:0,
        code: this.hexiao.code
      }
      this.showLoading()
      this.$api.order.orderHexiao(params, res => {
        this.hideLoading()
        if(res.errcode==0) {
          if(params.is_hexiao==1) {
            this.success(res.errmsg)
            this.seachCode()
          } else {
            this.hexiaoInfo = res.data
          }
        } else {
          this.fail(res.errmsg)
        }
      })
    }
  }
};
</script>
<style scoped>
</style>
