<template>
  <div class="">
    <el-form class="demo-form-inline" :inline="true" size="mini">
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入订单号" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-input v-model="search.code" placeholder="请输入核销码" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="search.status" placeholder="请选择状态" size="small" clearable>
          <el-option :key="1" label="未核销" :value="1"></el-option>
          <el-option :key="2" label="已核销" :value="2"></el-option>
          <el-option :key="3" label="已失效" :value="3"></el-option>
          <el-option :key="4" label="已退款" :value="4"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="search.store_id" placeholder="请选择自提点" size="small" clearable>
          <el-option :key="item.id" :label="item.title" :value="item.id" v-for="(item) in storeList"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table
        :data="tableData"
        ref="rfTable"
        style="width: 100%;"
        size="mini">
      <el-table-column
          prop="order_sn"
          label="订单号">
      </el-table-column>
      <el-table-column
          prop="member_id"
          label="下单人">
        <template slot-scope="scope">
          <div v-if="scope.row.member" class="member-box">
            <img :src="scope.row.member.avatar" />
            <div>
              <div>{{scope.row.member.name}}（{{scope.row.member.user_id}}）</div>
              <div>{{scope.row.member.mobile}}</div>
            </div>
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="hexiao_code"
          align="center"
          label="核销码">
      </el-table-column>
      <el-table-column
          prop="store_name"
          align="center"
          label="提货点">
      </el-table-column>
      <el-table-column
          prop="hexiao_status_str"
          label="状态"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          prop="user_name"
          label="核销人"
          align="center"
          width="140">
        <template slot-scope="scope">
          {{scope.row.user_name || '-' }}
        </template>
      </el-table-column>
      <el-table-column
          prop="hexiao_time"
          label="核销时间"
          align="center"
          width="140">
        <template slot-scope="scope">
          {{scope.row.hexiao_time || '-' }}
        </template>
      </el-table-column>
      <el-table-column
          prop="ftime"
          label="下单时间"
          align="center"
          width="140">
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="changePage"
        layout="total, prev, pager, next"
        :page-size="search.limit"
        :total="search.total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: {
        current_page: 1,
        total: 0,
        limit: 5,
        keywords: '',
        status: '',
        code: '',
        store_id: ''
      },
      tableData: [],
      storeList: false
    }
  },
  components: {},
  props: {
  },
  created() {
    this.getStoreList()
    this.getList();
  },
  mounted() {
  },
  methods: {
    getStoreList() {
      this.$api.merchant.storeIndex({page_size: 100}, res => {
        if(res.errcode==0) {
          this.storeList = res.data.data
        }
      })
    },
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords,
        code: that.search.code,
        status: that.search.status,
        store_id: that.search.store_id,
      }
      this.$api.order.orderSelffetchLog(param, function (res) {
        that.hideLoading()
        if (res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    changePage(page) {
      this.search.current_page = page
      this.getList()
    },
  }
}
</script>

<style scoped>
</style>
